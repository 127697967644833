module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":0.2,"once":true,"rootMargin":"0% 100%"},
    },{
      plugin: require('../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1280,"quality":90,"withWebp":true,"srcSetBreakpoints":[576],"linkImagesToOriginal":false,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../../@solublestudio/gatsby-theme-soluble-source/gatsby-browser.js'),
      options: {"plugins":[],"primaryLanguage":"en","language":{"en":""},"pathsByLanguage":{"openposition":{"en":"open-positions"},"Blog":{"en":"blog"},"Category":{"en":"blog"}},"datocms":{"apiToken":"7e8bf6b2bedd3fa7b2db56e4a4a395","previewMode":false,"modelPages":["Page","OpenPosition","Blog","Category"],"modelsPagination":{"Blog":{"pagination":{"firstPageItems":9,"perPage":9},"templateKey":"post-page","sectionKey":"BlogPostsSection","itemsKey":"blogPosts"}}},"local":false},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"unusuals","short_name":"unusuals","start_url":"/","background_color":"#101010","theme_color":"#ec37e1","display":"minimal-ui","icon":"src/img/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"263f0103f8f5cebce31daefa0e426cbb"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PBB3STH","defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-always-route-change","includeInDevelopment":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
